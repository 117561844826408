@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Bungee';
    src: url('/public/Bungee-Regular.ttf') format('truetype');
}

* {
    font-family: 'Bungee', sans-serif;
}

.waving-hand {
    animation-name: wave-animation;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 70% 70%;
    display: inline-block;
}

@keyframes wave-animation {
    0% {
        transform: rotate(0.0deg)
    }

    15% {
        transform: rotate(14.0deg)
    }

    /* The following five values can be played with to make the waving more or less extreme */
    30% {
        transform: rotate(-8.0deg)
    }

    40% {
        transform: rotate(14.0deg)
    }

    50% {
        transform: rotate(-4.0deg)
    }

    60% {
        transform: rotate(10.0deg)
    }

    70% {
        transform: rotate(0.0deg)
    }

    /* Reset for the last half to pause */
    100% {
        transform: rotate(0.0deg)
    }
}

.moving-zooming-out {
    animation: zoom-out-in 2s ease-in-out infinite;
}

@keyframes zoom-out-in {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.moving-zooming-out-fast {
    animation: zoom-out-in 1s ease-in-out infinite;
}

@keyframes zoom-out-in {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}


.wave-text {
    font-size: 2em;
    background: linear-gradient(to right, red 0%, orange 20%, blue 40%, pink 60%, red 80%);
    background-clip: text;
    animation: Color 4s linear infinite;
    -webkit-animation: Color 4s ease-in-out infinite;
}


@keyframes Color {
    0% {
        color: #A0D468;
    }

    20% {
        color: #4FC1E9;
    }

    40% {
        color: #FFCE54;
    }

    60% {
        color: #FC6E51;
    }

    80% {
        color: #ED5565;
    }

    100% {
        color: #AC92EC;
    }
}

@-moz-keyframes Color {
    0% {
        color: #A0D468;
    }

    20% {
        color: #4FC1E9;
    }

    40% {
        color: #FFCE54;
    }

    60% {
        color: #FC6E51;
    }

    80% {
        color: #ED5565;
    }

    100% {
        color: #AC92EC;
    }
}

@-webkit-keyframes Color {
    0% {
        color: #A0D468;
    }

    20% {
        color: #4FC1E9;
    }

    40% {
        color: #FFCE54;
    }

    60% {
        color: #FC6E51;
    }

    80% {
        color: #ED5565;
    }

    100% {
        color: #AC92EC;
    }
}


.p-hero__video-embed {
    --background-color: #171717;
    --border-color: #525252;
    --accent-color: #dbc900;

    position: relative;
    z-index: 1;
    overflow: hidden;
    box-shadow: 0 0 2rem var(--background-color);
    border: 0.2rem solid var(--accent-color);
    background-color: var(--border-color);
    border-radius: 2rem;
    ;
}